.projekt-akte-info-date-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  p {
    padding: 10px 10px 10px 0;
  }
}
.projekt-akte-section-title {
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: bold;
  &-first {
    @extend .projekt-akte-section-title;
    margin-top: 0;
  }
}

.projekt-akte-info-gauge-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .projekt-akte-info-data-wrapper {
    .projekt-akte-info-label {
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      label {
        width: 50%;
      }
      p {
        text-align: start;
      }
    }
  }
  .projekt-akte-info-gauge-component {
    max-width: 200px;
    width: 100%;
    p {
      text-align: center !important;
      padding: 10px 0;
    }
  }
}

.projekt-akte-milestone-workdays-toggle {
  margin: 0 10px;
}

@media screen and (min-width: 1200px) {
  .projekt-akte-info-gauge-data-wrapper {
    flex-direction: row;
    .projekt-akte-info-data-wrapper {
      width: 50%;
      .projekt-akte-info-label {
        label {
          max-width: 400px;
        }
      }
    }
  }
  .projekt-akte-info-section-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .projekt-akte-info-milestone-section {
      width: 50%;
      margin-left: 20px;
    }
    .projekt-akte-info-role-section {
      width: 50%;
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 400px) {
  .projekt-akte-info-date-wrapper {
    flex-direction: column;
    align-items: baseline;
  }
  .projekt-akte-info-gauge-data-wrapper {
    flex-direction: column;
    .projekt-akte-info-data-wrapper {
      .projekt-akte-info-label {
        flex-direction: column;
      }
    }
  }
}
