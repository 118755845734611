.column-align {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  .license-icon {
    height: 1rem;
    width: auto;

    &.valid path {
      fill: #0dab94 !important;
    }

    &.invalid path {
      color: #ee4f4b !important;
    }
  }
}

.intro-element-wrapper {
  display: flex;
  align-items: flex-end;

  .license-icon-wrapper {
    width: 100%;
  }

  .LeanAdmin-License-Prozess {
    width: 100%;

    .checkbox-component-wrapper {
      justify-content: center;
    }
  }

  .intro-element-spacer {
    flex-grow: 1;
  }

  &.one-active-license {
    width: 48% !important;
  }

  &.two-active-license {
    width: 30% !important;
  }

  .license-image {
    margin-bottom: 10px;
    height: 30px;
    width: 30px;
  }
}
.lean-admin-check-entry-search {
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
}
.lean-admin-check-entry-wrapper {
  border-bottom: 1px solid lightgray;
  &:last-child {
    border-bottom: none;
  }
  .lean-admin-check-entry {
    display: flex;
    align-items: center;

    > p {
      width: 25%;
      text-align: start;
    }

    &-status {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      flex-grow: 1;
    }

    .lean-admin-check-entry-link-button {
      cursor: pointer;
    }

    .gauge-component-wrapper {
      max-width: 200px;
      margin: 5px 25px;
      p {
        text-align: center;
      }
    }
  }
  .lean-admin-check-entry-last-update {
    p {
      font-size: 12px;
      text-align: start;
    }
  }
}

@media (max-width: 1000px) {
  .intro-element-wrapper {
    align-items: center;
    &.one-active-license,
    &.two-active-license {
      width: 100% !important;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1200px) {
  .lean-admin-check-entry-wrapper {
    flex-direction: column;
    .lean-admin-check-entry {
      flex-direction: column;
    }

    p {
      text-align: center !important;
      margin-top: 15px;
      width: 100%;
    }
  }
  .intro-element-wrapper {
    flex-direction: column;
  }
}
