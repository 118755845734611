.projekt-akte-entry-wrapper {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }

  .projekt-akte-entry-info-func {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 20px;
    font-size: 12px;

    .projekt-akte-entry-delete-wrapper {
      margin-right: 10px;

      svg {
        cursor: pointer;
        height: 20px;
        width: 20px;

        path {
          fill: #ee4f4b;
        }
      }
    }
  }

  .projekt-akte-flex-grow-one-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
  }

  .projekt-akte-entry-data {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .gauge-component-wrapper {
      max-width: 200px;
      width: 100%;
      margin: 5px 25px;

      p {
        text-align: center;
      }
    }

    .milestone-bar-wrapper {
      width: 70%;
      max-width: 700px;
    }

    .projekt-akte-entry-title {
      cursor: pointer;
      width: 30%;
      max-width: 300px;
    }
  }
}

@media (max-width: 600px) {
  .projekt-akte-entry-wrapper {
    .projekt-akte-flex-grow-one-wrapper {
      flex-direction: column;
      align-items: center;
    }
    .projekt-akte-entry-data {
      flex-direction: column;
      align-items: center;
      .gauge-component-wrapper {
        margin: 0;
      }
      .projekt-akte-flex-grow-one-wrapper {
        width: 100%;
      }
      .milestone-bar-wrapper {
        width: 70%;
        font-size: 12px;
      }
      .projekt-akte-entry-title {
        width: 100%;
        p {
          text-align: center;
        }
      }
    }
  }
}
