.user-list-functions-wrapper {
  display: flex;
  flex-direction: row;
}

.user-list-actions-table-wrapper {
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.user-list-status-wrapper,
.user-list-delete-wrapper,
.user-list-edit-wrapper {
  cursor: pointer;
  text-align: center;

  svg {
    height: 20px;
    width: 20px;
  }

  &.user-list-status-blocked {
    svg {
      path {
        fill: #ee4f4b;
      }
    }
  }

  &.user-list-status-check {
    svg {
      path {
        fill: #0dab94;
      }
    }
  }

  &.user-list-delete-wrapper {
    svg {
      path {
        fill: #ee4f4b;
      }
    }
  }
}

.logo-administration-image-wrapper {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #333333;
  background: lightgray;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: calc(100% - 10px);
    max-width: 100%;
  }

  svg {
    height: 30px;
    width: 30px;
  }
}

@media (max-width: 950px) {
  .user-list-functions-wrapper {
    flex-direction: column;
  }
}
