.projektakte-wrapper-add-search {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (max-width: 690px) {
  .projektakte-wrapper-add-search {
    flex-direction: column;
  }
}

.projektakte-info-prozessmap-wrapper-image {
  width: 100%;
}
