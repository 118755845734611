.sipoc-configuration-list-title {
  font-weight: bold;
  margin: 10px 0;
}
.sipoc-configuration-list {
  margin-top: 10px;
  width: calc(100% - 13px);

  li {
    display: flex;
    align-items: center;
    padding: 5px 0;
    .sipoc-configuration-download {
      cursor: pointer;
      padding: 0 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    > div {
      &:first-child {
        flex-grow: 1;
      }

      &.sipoc-configuration-remove {
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #ee4f4b;
          }
        }
      }
    }
  }
}

.false-input {
  input {
    color: #ee4f4b;
  }
}
