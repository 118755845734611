.pdca-quartered-circle {
  width: 50px;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  margin: 0 auto;
  circle {
    fill: transparent;
    stroke: #333333;
    stroke-width: 2;
  }

  path {
    fill: #333333;
  }
}

.quick-filter-settings-headline,
.quick-filter-settings {
  margin-left: 10px;
  font-size: 12px;
}

.quick-filter-settings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 30%;
    min-width: 190px;
  }
}

.pdca-table-missing-info,
.pdca-table-missing-info * {
  color: #ee4f4b;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-top: 1px solid black;
  margin-top: 15px;
  &-entry-wrapper {
    display: flex;
    align-items: center;
    margin: 5px 0 0 5px;
  }
  &-icon {
    margin: 0;
    width: 30px;
  }
}

@media (min-width: 550px) {
  #new-pdca-button-wrapper {
    button {
      position: absolute;
      top: 50px;
      left: 40px;
    }
  }
}
