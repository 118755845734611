.process-mapping-drawing-wrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  position: relative;

  .process-mapping-drawing-stage-wrapper {
    flex-grow: 1;
    overflow-x: auto;
    scrollbar-width: none;
    height: calc(100vh - 212px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .process-mapping-last-updated {
    padding-top: 5px;
    font-size: 12px;
  }
}

.process-mapping-drawing-toolbox-wrapper {
  width: 300px;
  min-width: 300px;

  .toolbox-spacer {
    height: 1px;
    background: #333333;
  }

  .process-mapping-drawing-toolbox-item {
    margin: 10px 0;
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &.process-mapping-drawing-toolbox-item-files {
      svg {
        padding: 5px 20px;
        border: 1px solid transparent;
        cursor: pointer;
        height: 40px;
        border-radius: 3px;
      }
    }

    img,
    svg {
      border: 1px solid transparent;
      cursor: pointer;
      width: 50%;
      height: 60px;
      border-radius: 3px;

      &.selected {
        border: 1px solid #0dab94;
        background: rgba(13, 171, 148, 0.1);
      }
    }
  }
}

@media (max-width: 950px) {
  .horizontal-sub-menu-component-wrapper {
    margin-bottom: 20px;
  }

  .process-mapping-drawing-wrapper {
    flex-direction: column;

    .process-mapping-drawing-stage-wrapper {
      height: calc(100vh - 250px);
    }

    .process-mapping-drawing-toolbox-wrapper {
      width: 100%;
    }
  }
}
