.sipoc-detail-line-export-text-wrapper {
  word-break: break-all;
}

.sipoc-detailview-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;

  .type-definition-example-line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 15px;
    padding-top: 5px;
    border-top: 1px solid black;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .type-definition-example-line-text {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }

  .sipoc-detailview-text-content-wrapper {
    width: 100%;

    .sipoc-detailview-flex-wrapper {
      display: flex;
      flex-direction: row;

      .sipoc-detailview-role-wrapper-wrapper {
        background: #e6e6e6;

        .sipoc-detailview-role-wrapper {
          position: relative;
          .sipoc-detailview-role-line {
            position: absolute;
            width: 50px;
            height: 100%;
            transform: rotate(-90deg);

            .current-verantwortlicher-role {
              font-weight: bold;
              color: black;
            }

            > div {
              text-align: right;
            }
          }
        }
      }

      .sipoc-detailview-input-wrapper {
        flex-grow: 1;

        .sipoc-detailview-input-title {
          background: #13c5ab;
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            color: white;

            &:first-child {
              flex-grow: 1;
            }

            &:last-child {
              cursor: pointer;
            }

            svg {
              width: 14px;
              height: 14px;

              path {
                fill: white;
              }
            }
          }
        }
      }

      .sipoc-detailview-process-wrapper {
        flex-grow: 1;

        .sipoc-detailview-process-title {
          background: #13c5ab;
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            color: white;

            &:first-child {
              flex-grow: 1;
            }

            &:last-child {
              cursor: pointer;
            }

            svg {
              width: 14px;
              height: 14px;

              path {
                fill: white;
              }
            }
          }
        }
        .sipoc-detailview-process-line-draggable {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          &--drag-icon svg {
            margin-left: 5px;
            width: 25px;
            height: 25px;
            display: block;
            cursor: move;

            path {
              fill: #0dab94;
            }
          }
          &--drag-icon-mobile {
            display: none;

            svg {
              path {
                fill: #0dab94;
              }
            }
          }
          // when pointer is coarse, touch actions are received and drag/drop
          // doesn't work, so we display the up/down icons
          @media (pointer: coarse) {
            &--drag-icon {
              display: none;
            }
            &--drag-icon-mobile {
              display: flex;
              svg {
                margin-left: 5px;
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }

      .sipoc-detailview-output-wrapper {
        flex-grow: 1;

        .sipoc-detailview-output-title {
          background: #13c5ab;
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            color: white;

            &:first-child {
              flex-grow: 1;
            }

            &:last-child {
              cursor: pointer;
            }

            svg {
              width: 14px;
              height: 14px;

              path {
                fill: white;
              }
            }
          }
        }
      }

      .sipoc-detailview-title-wrapper {
        display: flex;
        flex-direction: row;
        background: #0dab94;
        flex-grow: 1;

        .sipoc-detailview-title {
          flex-grow: 1;
          padding-top: 10px;
          padding-left: 10px;
          font-weight: bold;
          color: white;
        }

        .sipoc-detailview-close {
          cursor: pointer;
          margin-top: 10px;
          margin-right: 10px;

          svg {
            height: 20px;
            width: 20px;

            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}

#sipoc-overview-export-wrapper {
  &.sipoc-overview-export-wrapper-hidden {
    position: fixed;
    top: 0;
    left: 9999px;
    min-width: 720px;
  }
}

.sipoc-overview-rotate-text {
  position: relative;
  width: 40px;
  min-width: 40px;
  margin-right: 10px;

  > div {
    position: absolute;
    top: calc(50% - 8px);
    left: 2px;
    color: #eeeeee;
    font-weight: bold;
    transform: rotate(-90deg);
  }

  &.additional-left {
    > div {
      left: -12px;
    }
  }
}

.sipoc-overview-processes-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  .sipoc-overview-rotate-text {
    background: #333333;
    min-height: 93px;
  }

  .sipoc-overview-processes-entry {
    cursor: pointer;
    user-select: none;
    display: flex;
    margin: 5px 0;
  }
}

.sipoc-overview-processes-entry-checklist-wrapper {
  margin-left: 10px;
  display: flex;
  overflow: visible;

  svg {
    height: 16px;
    width: 16px;

    &.ok {
      path {
        fill: #0dab94;
      }
    }

    &.red {
      path {
        fill: #77180e;
      }
    }
    &.yellow {
      path {
        fill: #ebb93b;
      }
    }
  }
}

.sipoc-overview-wrapper {
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #0dab94;

  .sipoc-overview-rotate-text {
    background: #0dab94;
    min-height: 73px;
  }

  .sipoc-overview-role-wrapper {
    flex-grow: 1;

    .sipoc-overview-role-index-number-wrapper {
      position: relative;
      height: 16px;

      .sipoc-overview-role-index-number-text {
        position: absolute;
        user-select: none;
        cursor: pointer;
      }
    }

    .sipoc-overview-role-line-wrapper {
      margin: 5px 0;
      display: flex;
      flex-direction: row;

      .sipoc-overview-role-line-dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        background: black;
        position: absolute;
        border-radius: 50%;

        &.sipoc-overview-role-line-dot-black {
          background: black;
        }

        &.sipoc-overview-role-line-dot-red {
          background: red;
        }
      }

      .sipoc-overview-role-line {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;

        .sipoc-overview-role-line-line {
          border-bottom: 2px solid #33333360;

          &.sipoc-overview-role-line-line-dash {
            border-bottom: 2px dashed #33333360;
          }
        }
      }
    }
  }
}

.sipoc-detailview-checkbox-wrapper {
  position: relative;
  background: #e6e6e6;
  flex-shrink: 0;

  .sipoc-detailview-checkbox {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 13px;
    background: gray;
    cursor: pointer;

    &.sipoc-detailview-checkbox-view {
      background: transparent;
    }
  }
}

.sipoc-detailview-content-input-line {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .sipoc-detailview-input-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    .sipoc-detailview-input-text-number {
      margin-left: 10px;
      font-weight: bold;
    }

    .sipoc-detailview-input-text-content {
      flex-grow: 1;
      margin-left: 10px;

      .text-area-component {
        margin: 5px 0;
      }
    }

    .checkbox-component-wrapper {
      width: unset !important;
    }
    .sipoc-detailview-input-config-indicator {
      margin-right: 5px;
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: #0dab94;
        }
      }
    }
    .sipoc-detailview-input-text-edit,
    .sipoc-detailview-input-text-trash,
    .sipoc-detailview-input-text-edit-todos {
      margin-left: 10px;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: red;
        }
      }

      &.sipoc-detailview-input-text-edit {
        svg {
          path {
            fill: #0dab94;
          }
        }
      }
      &.sipoc-detailview-input-text-edit-todos {
        svg {
          path {
            fill: #7057ff;
          }
        }
      }
    }
  }
}

.definition-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &.dot-VERANTWORTLICHDURCHFUEHREND,
  &.dot-verantwortlich-durchfuehrend {
    background: #77180e !important;
  }

  &.dot-VERANTWORTLICH,
  &.dot-verantwortlich {
    background: #77180e !important;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "X";
    }
  }

  &.dot-DURCHFUEHREND,
  &.dot-durchfuehrend {
    background: #c0c0c0 !important;
    color: #8f8f8f;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "X";
    }
  }

  &.dot-MITWIRKEND,
  &.dot-mitwirkend {
    background: white !important;
    width: 13px;
    height: 13px;
    border: 1px solid #8f8f8f;
  }

  &.dot-INFORMIERT,
  &.dot-informiert {
    background: #c0c0c0 !important;
  }

  &.dot-EMPFAENGER,
  &.dot-empfaenger {
    background: #007a93 !important;
  }
}

.box-special-icon {
  height: 70px !important;
  width: 70px !important;
}

.sipoc-detail-export-wrapper-export {
  background: white;
  position: fixed;
  left: 9999px;
  width: 1200px;
  top: 0;
}

.sipoc-config-pop-up-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: white;
    font-size: 12px;
  }
  p:first-child {
    font-size: 16px;
  }
}
