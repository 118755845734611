body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 5rem;
  color: darkred;
  text-align: center;
}

.notification__item {
  border-left: none !important;

  &.notification__item--info {
    background-color: #0dab94;
  }
}

.flex-inline-box-component-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;

  &.vertical-center {
    align-items: center;
  }
}

* .input-component-label-wrapper {
  text-align: left !important;
}

@media (max-width: 950px) {
  .flex-inline-box-component-wrapper {
    flex-direction: column;
  }
}
