.lean-admin-modul-prozess-bereiche-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .add-subtract-button-wrapper {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: min-content;
    align-items: center;
    justify-content: space-between;

    &-label {
      text-align: center !important;
      margin-bottom: 10px;
      margin-top: -10px;
    }

    .button-component-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      width: 50px;
    }
  }

  .box-component-wrapper {
    max-width: 400px;

    .lean-admin-modul-entry-subtext-wrapper {
      display: flex;
      padding-right: 13px;

      p {
        flex-grow: 1;
      }

      .lean-admin-modul-entry-copy {
        cursor: pointer;
        margin-right: 5px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #0dab94;
          }
        }
      }

      .lean-admin-modul-entry-edit {
        cursor: pointer;
        margin-right: 5px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #7057ff;
          }
        }
      }

      .lean-admin-modul-entry-remove {
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #ee4f4b;
          }
        }
      }
    }

    * .lean-admin-modul-entry-role-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .button-component-wrapper {
        width: 50px;
        margin: 5px 10px;
      }

      .lean-admin-modul-entry-remove {
        cursor: pointer;
        margin-right: 13px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: #ee4f4b;
          }
        }
      }
    }

    * .lean-admin-modul-entry-role-list {
      margin-top: 10px;
      width: calc(100% - 13px);

      li {
        display: flex;
        align-items: center;
        padding: 5px 0;

        > div {
          &:first-child {
            flex-grow: 1;
          }

          &.lean-admin-modul-entry-role-remove,
          &.lean-admin-modul-entry-role-edit {
            cursor: pointer;

            svg {
              width: 20px;
              height: 20px;

              path {
                fill: #ee4f4b;
              }
            }
          }

          &.lean-admin-modul-entry-role-edit {
            margin-right: 5px;
            margin-left: 5px;
            svg {
              path {
                fill: #7057ff;
              }
            }
          }
        }
      }
    }
  }
}

.target-company-info {
  padding-bottom: 40px;
}
