.delete-button-wrapper {
  cursor: pointer;
  text-align: center;
  svg {
    height: 20px;
    width: 20px;
    path {
      fill: #ee4f4b;
    }
  }
}

.hidden-prozessmapping-image {
  position: fixed;
  top: 9999px;
  left: 9999px;
}
