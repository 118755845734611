.prozess-overview {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2vh;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
    max-width: 900px;
    h3,
    p {
      &.invalid {
        color: #ee4e4b;
      }
      margin: 8px 0;
    }
  }

  &__description {
    opacity: 0.5;
  }

  &__indicator-wrapper,
  &__chance-risks-wrapper,
  &__input-output-wrapper,
  &__documents-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .kpi-wrapper,
    .description-wrapper,
    .risk-wrapper,
    .chance-wrapper,
    .input-wrapper,
    .output-wrapper,
    .document,
    .single-document-wrapper {
      display: flex;
      gap: 12px;
      align-items: center;

      p {
        flex-basis: 40%;
      }
      .download-icon {
        height: 20px;
        width: 20px;
      }
    }

    .filename {
      flex: 1;
    }

    .delete-wrapper {
      display: flex;
      align-items: center;

      .flex-wrapper {
        flex: 1;
      }

      svg {
        height: 20px;
        width: 20px;
        padding-left: 20px;
        margin-top: 24px;
      }
    }
  }
}

.gant-diagram__content-box {
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;

  p {
    margin-left: auto;
  }
}
